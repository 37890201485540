<template>
  <div>
    <v-overlay :z-index="100" :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="grey darken-2"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div class="text-h5">Transactions</div>

    <!-- START BUTTONS -->
    <div class="text-right">
      <v-btn
          :disabled="selectedTrans.length < 1"
          class="ml-2 mt-2"
          color="primary"
          @click="categorizeSelected()"
      >
        Categorize Selected
        <v-icon class="pl-3" dark>
          mdi-folder-arrow-up
        </v-icon>
      </v-btn>
    </div>
    <!-- END BUTTONS -->

    <!-- TRANSACTIONS TABLE START -->
    <v-data-table
        class="mt-5"
        :headers="tableHeaders"
        :items="filteredData"
        :footer-props="{'items-per-page-options': pagingOptions}"
        :items-per-page="25"
        sort-by="date"
        :sort-desc="true"
        item-key="id"
        show-select
        v-model="selectedTrans"
    >
      <template v-slot:top>
        <v-expansion-panels
            v-model="panels"
            multiple
            flat
            class="mb-3"
        >
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Summary</v-expansion-panel-header>
            <v-expansion-panel-content>
               Summary
              <v-row class="text-right">
                <v-col cols="6" md="10">

                </v-col>
                <v-col cols="6" md="2">
                  <v-card elevation="6" outlined>
                    <v-card-title><div class="text-center">Total</div></v-card-title>
                    <v-card-subtitle>{{ formattedTotalAmount }}</v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header ripple class="d-flex d-md-none">Filtering</v-expansion-panel-header>
            <v-expansion-panel-content>
               Filtering
              <v-row>
                <v-col cols="12" md="2">
                  <v-select
                      :items="options"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.periodFrom"
                      label="From"
                      placeholder="Select month"
                      clearable
                  />
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="toOptions"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.periodTo"
                      label="To"
                      placeholder="Select month"
                      clearable
                  />
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                      :items="creditOptions"
                      item-value="id"
                      item-text="text"
                      v-model="filtering.creditId"
                      label="Credit Account"
                      placeholder="Select Account"
                      clearable
                  />
                </v-col>

                <v-col cols="12" md="2">
                  <v-text-field
                      v-model="filtering.search"
                      label="Search by description"
                      clearable
                  >

                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      v-model="filtering.categoryId"
                      :items="tagCategories"
                      item-value="id"
                      item-text="description"
                      label="Category"
                      :disabled="filtering.uncategorizedOnly"
                      clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-switch
                      v-model="filtering.excludePayments"
                      label="Exclude Payments"
                  >
                  </v-switch>
                </v-col>

                <v-col cols="12" md="2">
                  <v-switch
                      v-model="filtering.uncategorizedOnly"
                      label="Uncategorized Only"
                  >
                  </v-switch>
                </v-col>

              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="categorizeCurrent(item)"
        >
          mdi-folder-arrow-up
        </v-icon>
      </template>
    </v-data-table>
    <!-- TRANSACTIONS TABLE END -->

    <!-- PAYMENT CARD -->
    <v-dialog
        v-model="categorizeMode"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Categorize transaction</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="tagCategory"
                    :items="tagCategories"
                    item-value="id"
                    item-text="description"
                    dense
                    label="Category"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="categorizeMode = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCategories()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END PAYMENT CARD -->
  </div>
</template>

<script>
import {formatAsCurrency} from "../../services/utils";
export default {
  name: "Transactions",

  data () {
    return {
      selectedTrans: [],
      tagCategory: 0,
      categorizeMode: false,
      panels: [],
      pagingOptions: [10,25,50,100,-1],
      paymentDateMenu: false,
      filtering: {
        periodFrom: null,
        periodTo: null,
        creditId: null,
        categoryId: null,
        search: '',
        excludePayments: false,
        uncategorizedOnly: false,
      },
      tableHeaders: [
        {
          value: 'display_date',
          text: 'Date',
          sortField: 'date'
        }, {
          value: 'credit_name',
          text: 'Credit Account',
        }, {
          value: 'description',
          text: 'Description',
        }, {
          value: 'category_name',
          text: 'Category',
        }, {
          value: 'formattedAmount',
          text: 'Amount',
          align: 'end'
        }, { text: 'Actions', value: 'actions', sortable: false },
      ],
      mobileWidth: 767,
    }
  },

  mounted() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
        this.panels = [];
        break;
      case 'md':
      case 'lg':
      case 'xl':
        this.panels = [0,1];
        break;
    }

    this.$store.dispatch('fetchTransactionsPeriods').then(() => {
      if (this.filtering.periodFrom == null && this.filtering.periodTo == null && (this.options.length > 0)) {
        this.filtering.periodFrom = this.options[0].id;
      }

      this.$store.dispatch('fetchCredit').then(() => {
        this.$store.dispatch('fetchExpensesCategories').then(() => {
          this.$store.dispatch('fetchTransactions');
        });
      });
    });
  },

  computed: {
    accounts() {
      return this.$store.state.accounts.accounts;
    },

    options() {
      return this.$store.state.transactions.periodsOptions;
    },

    toOptions() {
      return this.options.filter((o) => (
          this.filtering.periodFrom == null
          || o.id >= parseInt(this.filtering.periodFrom)
      ));
    },

    creditOptions() {
      return this.$store.state.credit.credit.data.map((credit) => {
        return {
          id: credit.id,
          text: credit.description
        };
      })
    },

    transactionData() {
      return this.$store.state.transactions.transactions.data;
    },

    filteredData() {
      return this.transactionData.filter(
          (r) => (this.filtering.periodFrom == null || this.filtering.periodFrom <= r.date)
      ).filter((r) => (this.filtering.periodTo == null || this.filtering.periodTo > r.date)
      ).filter((r) => (this.filtering.creditId == null || this.filtering.creditId == r.credit_id)
      ).filter((r) => (this.filtering.categoryId == null || this.filtering.uncategorizedOnly == true || this.filtering.categoryId == r.category_id)
      ).filter((r) => (this.filtering.excludePayments == false || r.amount > 0)
      ).filter((r) => (this.filtering.uncategorizedOnly == false || r.category_id < 1)
      ).filter((r) => {
        return this.filtering.search == null || r.description.toLowerCase().includes(this.filtering.search.toLowerCase());
      });
    },

    loading() {
      return this.$store.state.transactions.loading;
    },

    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },

    totalAmount() {
      let total = 0;
      this.filteredData.forEach((r) => total += r.amount);
      return Math.round(total * 100) / 100;
    },

    formattedTotalAmount() {
      return formatAsCurrency(this.totalAmount);
    },

    categories() {
      return this.$store.state.expenses.categories;
    },

    tagCategories() {
      let tmp = [];
      this.categories.forEach((c) => {
        c.children.forEach((chld) => {
          tmp.push({
            id: chld.id,
            description: c.description + ' - ' + chld.description
          });
        })
      });

      return tmp;
    },

    selectedIds() {
      return this.selectedTrans.map((trans) => {
        return trans.id;
      });
    }
  },

  methods: {
    saveCategories() {
      this.$store.dispatch('categorizeTransactions', {
        category_id: this.tagCategory,
        ids: this.selectedIds
      })
      this.selectedTrans = [];
      this.categorizeMode = false;
    },

    categorizeSelected() {
      this.tagCategory = 0;
      this.categorizeMode = true;
    },

    categorizeCurrent(item) {
      this.tagCategory = 0;
      this.selectedTrans = [item];
      this.categorizeMode = true;
    }
  }
}
</script>

<style scoped>

</style>